// <div data-controller="fb--datepicker">
//
// </div>
import {Controller} from "@hotwired/stimulus";
import Datepicker from 'flowbite-datepicker/Datepicker';

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = [""];
    static classes = [""]
    static values = {};

    initialize() {
        this.datepicker = new Datepicker(this.element, {
            format: 'dd/mm/yyyy'
        });
    }
}
