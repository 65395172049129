// <div data-controller="copy-to-clipboard">
//
// </div>
import {Controller} from "@hotwired/stimulus";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = ["item", "text"];
    static classes = [""]
    static values = {}

    initialize() {
        this.itemTarget.addEventListener("click", this.copy_to_clipboard.bind(this));
    }

    copy_to_clipboard(e) {
        e.preventDefault()
        navigator.clipboard.writeText(this.textTarget.textContent.trim());
        this.change_text()
    }

    change_text() {
        let itemElement = this.itemTarget
        let prev_text = itemElement.innerHTML

        // Keep same width of the button
        itemElement.style.width = itemElement.offsetWidth + "px"
        // Flex to center the text
        itemElement.style.display = "flex"
        itemElement.style.justifyContent = "center"
        itemElement.style.alignItems = "center"

        itemElement.innerHTML = "Copiado!"


        setTimeout(function () {
            itemElement.innerHTML = prev_text
            itemElement.style.width = ""

            // Flex to center the text
            itemElement.style.display = ""
            itemElement.style.justifyContent = ""
            itemElement.style.alignItems = ""
        }, 3000);
    }
}
