// <div data-controller="process--payment">
//
// </div>
import {Controller} from "@hotwired/stimulus";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = ["paymentMethod", "payNow", "payNowPercentInput", "payNowTotalFirstPayment", "payNowCreditValue", "payNowValue", "payNowValueWithoutMixed",
        "enablePaymentButton", "disablePaymentButton", "credit", "creditValue","commercialDiscount", "commercialDiscountOn", "treatmentAtTreatmentValue",
        "patientReferredContacts", "patientReferredContactsOn", "patientHaveHealthInsurance",
        "patientHaveHealthInsuranceOn", "secondPayment", "treatmentAtTreatment",
        "multipleInstallments", "multipleInstallmentsPercentInput", "multipleInstallmentsMonthsInput", "payMonthlyValue",
        "multipleInstallmentsStartDateInput", "multipleInstallmentsReachDateInput", "payTotalValue"
    ];
    static classes = [""]
    static values = {
        total: Number, mixedPayment: Boolean
    };

    mixedPayment = false

    initialize() {
        this.mixedPayment = this.mixedPaymentValue
    }

    showPaymentMethod() {
        let payment_method_type = this.paymentMethodTarget.value

        this.payNowTarget.classList.add('hidden')

        this.creditTarget.classList.add('hidden')

        this.treatmentAtTreatmentTarget.classList.add('hidden')

        this.multipleInstallmentsTarget.classList.add('hidden')

        switch (payment_method_type) {
            case "multiple_installments":
                this.enablePaymentButtonTarget.classList.remove("hidden")
                this.multipleInstallmentsTarget.classList.remove('hidden')

                this.multipleInstallmentsPercentInputTarget.value = 100
                // this.multipleInstallmentsMonthsInputTarget.value = "3 meses"

                // Current month at beginning of the month
                this.multipleInstallmentsStartDateInputTarget.value = new Date().toISOString().slice(0, 10)
                this.commercialDiscountTarget.disabled = true
                break;
            case "pay_now":
                this.payNowTarget.classList.remove("hidden");
                this.enablePaymentButtonTarget.classList.remove("hidden")
                break;
            case "credit":
                this.creditTarget.classList.remove("hidden")
                this.creditValueTarget.innerHTML = this.totalValue.toLocaleString('pt-pt', {
                    style: 'currency', currency: 'EUR'
                })
                this.disableMixedPayment()
                this.enablePaymentButtonTarget.classList.add("hidden")
                this.commercialDiscountTarget.disabled = true
                break;
            case "treatment_to_treatment":
                this.treatmentAtTreatmentTarget.classList.remove("hidden")
                this.treatmentAtTreatmentValueTarget.innerHTML = this.totalValue.toLocaleString('pt-pt', {
                    style: 'currency', currency: 'EUR'
                })
                this.disableMixedPayment()
                this.enablePaymentButtonTarget.classList.add("hidden")
                this.commercialDiscountTarget.disabled = true
                break;
        }
    }

    enableMixedPayment() {
        this.enablePaymentButtonTarget.classList.add("hidden")

        // change first input placeholder
        //this.payNowTarget.children[0].querySelector('input').placeholder = "Valor do pronto pagamento"
        this.payNowTarget.children[1].classList.add('hidden')

        // // hide treatment to treatment payment method
        // this.paymentMethodTarget.querySelectorAll('option').forEach((option) => {
        //     if (option.value === "treatment_to_treatment") {
        //         option.classList.add('hidden')
        //         if (this.paymentMethodTarget.value === "treatment_to_treatment") {
        //             this.paymentMethodTarget.value = ""
        //             // trigger change event
        //             this.paymentMethodTarget.dispatchEvent(new Event('change'));
        //         }
        //     } else {
        //         option.classList.remove('hidden')
        //     }
        // })

        this.secondPaymentTarget.classList.remove('hidden')
        this.mixedPayment = true
    }

    disableMixedPayment() {
        this.enablePaymentButtonTarget.classList.remove("hidden")

        // change first input placeholder
        this.payNowTarget.children[0].querySelector('input').placeholder = "% da Valor Inicial"
        this.payNowTarget.children[1].classList.remove('hidden')

        // // show treatment to treatment payment method
        // this.paymentMethodTarget.querySelectorAll('option').forEach((option) => {
        //     option.classList.remove('hidden')
        // })

        this.secondPaymentTarget.classList.add('hidden')
        this.mixedPayment = false
    }

    clearPercent(e) {
        console.log("clearPercent")
        // 10% to 10
        e.currentTarget.value = e.currentTarget.value.replace("%", "")
    }
    calculatePayNow() {
        // debouncing
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.calculatePayNowDebounced()
        }, 500)

    }

    calculatePayNowDebounced() {
        let total = this.totalValue
        let percent = parseInt(this.payNowPercentInputTarget.value)

        if (!isNaN(percent)) {
            // transform 10 to 10%
            this.payNowPercentInputTarget.value = percent + "%"
        }


        let first_payment_method = this.paymentMethodTarget.value

        // Pronto pagamento + Crédito
        if (first_payment_method === "pay_now") {
           // this.payNowPercentInputTargets[3].classList.add('!hidden') // hide second payment pay now input


            let percent_value = total * (percent / 100)

            this.payNowTotalFirstPaymentTarget.innerHTML = percent_value.toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })

            this.payNowValueWithoutMixedTarget.innerHTML = (total - percent_value).toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })

            this.payNowCreditValueTarget.innerHTML = (total - percent_value).toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })

            //this.payNowValueTargets[4].innerHTML = (total - percent).toLocaleString('pt-pt', {
            //    style: 'currency', currency: 'EUR'
            //})
        }

        if (first_payment_method === "credit") {
            this.payNowPercentInputTargets[2].classList.add('!hidden') // hide second payment pay now input
            percent = parseInt(this.payNowPercentInputTargets[1].value)
            this.payNowValueTargets[1].innerHTML = percent.toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })

            this.payNowValueTargets[3].innerHTML = (total - percent).toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })
            this.payNowPercentInputTargets[2].value = (total - percent)

            this.payNowValueTargets[5].innerHTML = (total - percent).toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })
        }

        if (first_payment_method === "multiple_installments") {
            // payMonthlyValue = total / multipleInstallmentsPercentInput * multipleInstallmentsMonthsInput
            let multipleInstallmentsPercentInput = parseInt(this.multipleInstallmentsPercentInputTarget.value)
            let multipleInstallmentsMonthsInput = parseInt(this.multipleInstallmentsMonthsInputTarget.value)
            let payMonthlyValue = total * (multipleInstallmentsPercentInput / 100) / multipleInstallmentsMonthsInput
            let totalPayMonthlyValue = payMonthlyValue * multipleInstallmentsMonthsInput


            if(!isNaN(multipleInstallmentsPercentInput)){
                this.multipleInstallmentsPercentInputTarget.value = multipleInstallmentsPercentInput + "%"
            }

            console.log("Total: " + total)
            console.log("Total pago mensalmente: " + totalPayMonthlyValue)
            console.log("Percentagem: " + multipleInstallmentsPercentInput)
            console.log("Meses: " + multipleInstallmentsMonthsInput)
            console.log("Valor mensal: " + payMonthlyValue)

            this.payMonthlyValueTarget.innerHTML = payMonthlyValue.toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            }) + " p/ mês"


            // Using multipleInstallmentsStartDateInput, calculate when will be the date where the payment will reach 60% of the total value
            let multipleInstallmentsStartDateInput = this.multipleInstallmentsStartDateInputTarget.value
            let multipleInstallmentsStartDate = new Date(multipleInstallmentsStartDateInput)

            let sixtyPercentOfTotalValue = total * 0.6
            console.log("60% do total: " + sixtyPercentOfTotalValue)

            let monthsToReachSixtyPercent = Math.ceil(sixtyPercentOfTotalValue / payMonthlyValue)
            console.log("Meses para chegar aos 60%: " + monthsToReachSixtyPercent)

            let dateToReachSixtyPercent = new Date(multipleInstallmentsStartDate.setMonth(multipleInstallmentsStartDate.getMonth() + monthsToReachSixtyPercent))
            console.log("Data para chegar aos 60%: " + dateToReachSixtyPercent)


            // Em 05/05/2021 atinge 60%
            this.multipleInstallmentsReachDateInputTarget.value = "Em " + dateToReachSixtyPercent.toLocaleDateString('pt-PT', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            }) + " atinge 60%"
            this.payTotalValueTarget.innerHTML = totalPayMonthlyValue.toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })

            this.payNowCreditValueTarget.innerHTML = (total - totalPayMonthlyValue).toLocaleString('pt-pt', {
                style: 'currency', currency: 'EUR'
            })

        }

    }

// Checkboxes
    showCommercialDiscount() {
        let commercial_discount = this.commercialDiscountTarget.checked
        if (commercial_discount) {
            this.commercialDiscountOnTarget.classList.remove("hidden")
        } else {
            this.commercialDiscountOnTarget.classList.add("hidden")
        }
    }

    showPatientReferredContacts() {
        let patient_referred_contacts = this.patientReferredContactsTarget.checked
        if (patient_referred_contacts) {
            this.patientReferredContactsOnTarget.classList.remove("hidden")
        } else {
            this.patientReferredContactsOnTarget.classList.add("hidden")
        }
    }

    showPatientHaveHealthInsurance() {
        let patient_have_health_insurance = this.patientHaveHealthInsuranceTarget.checked
        if (patient_have_health_insurance) {
            this.patientHaveHealthInsuranceOnTarget.classList.remove("hidden")
        } else {
            this.patientHaveHealthInsuranceOnTarget.classList.add("hidden")
        }
    }

}
