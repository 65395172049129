import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="char-counter"
export default class extends Controller {
    static targets = ['input', 'output']

    connect() {
        this.inputTarget.addEventListener('input', this.update.bind(this))
        setTimeout(() => {
            this.update()
        }, 1000)
    }


    update() {
        let current_length = 0
        if (this.inputTarget.tagName === 'TEXTAREA') {
            current_length = this.inputTarget.value.length
        }
        if (this.inputTarget.tagName === 'TRIX-EDITOR') {
            current_length = this.inputTarget.editor.getDocument().toString().trim().length;
        }

        // Outputs the number of characters in the trix-editor
        // Outputs the number of sms messages (160 characters per message)
        let sms_count = Math.ceil(current_length / 160)
        this.outputTarget.innerHTML = current_length + ' palavras (' + sms_count + ' sms)'
    }
}
