// <div data-controller="image-uploader">
//
// </div>
import { Controller } from "@hotwired/stimulus";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = ["output", "input"]

  readURL() {
    var input = this.inputTarget
    var output = this.outputTarget

    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
      }

      reader.readAsDataURL(input.files[0]);
    }
    this.element.classList.add("uploaded")
  }

  browserPhotos() {
    this.inputTarget.click()
  }


}