// <div data-controller="list--table-link">
//
// </div>
import {Controller} from "@hotwired/stimulus";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = [""];
    static classes = [""]
    static values = {};

    initialize() {
        this.element.addEventListener("click", this.click.bind(this));
    }

    click(event) {
        // if target is input, or select, or target inside .tomselect-default class don't follow link
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'SELECT' || event.target.closest('.tomselect-default')) {
            return;
        }
        event.preventDefault();
        const url = this.element.dataset.link
        Turbo.visit(url)

    }
}
