// <div data-controller="start-texting">
//
// </div>
import {Controller} from "@hotwired/stimulus";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = ["cta", "showMore", "internCta", "formCta", "formCtaInternMode"];
    static classes = [""]
    static values = {};

    initialize() {
        this.element.addEventListener("click", () => this.toggleInternMode());
        this.element.addEventListener("click", () => this.hideTrixtoolbar());
        this.toggleInternMode();

        // if esc key is pressed on trix editor, reset the form
        this.element.querySelector("trix-editor").addEventListener("keydown", (event) => {
            if (event.key === "Escape") {
                this.show_more_reset();
            }
        })

    }

    show_more(event) {
        this.ctaTarget.classList.add("!hidden");
        this.showMoreTarget.classList.remove("hidden");
        this.element.querySelector("trix-editor").focus();
    }

    show_more_reset(event) {
        this.ctaTarget.classList.remove("!hidden");
        this.showMoreTarget.classList.add("hidden");
    }

    toggleInternMode() {
        if (this.internCtaTarget.checked) {
            this.formCtaTarget.classList.add("hidden")
            this.formCtaInternModeTarget.classList.remove("hidden")
            this.element.querySelector(".trix-container").classList.add("trix-intern-message")

        } else {
            this.formCtaTarget.classList.remove("hidden")
            this.formCtaInternModeTarget.classList.add("hidden")
            this.element.querySelector(".trix-container").classList.remove("trix-intern-message")
        }
    }

    reset() {
        // Reset trix editor
        let trixEditor = this.element.querySelector("trix-editor");
        trixEditor.editor.loadHTML("");
        trixEditor.dispatchEvent(new Event('change'));

    }

    hideTrixtoolbar() {
        // if radio message_type sms or whatsapp is checked, hide trix toolbar
        this.element.querySelectorAll("input[type=radio]").forEach((radio) => {
            radio.addEventListener("change", (event) => {
                if (event.target.value === "sms" || event.target.value === "whatsapp") {
                    this.element.querySelector(".trix-container").classList.add("hide-trix-toolbar")
                    this.reset();
                } else {
                    this.element.querySelector(".trix-container").classList.remove("hide-trix-toolbar")
                }

                if (event.target.value === "sms") {
                    // show char counter
                    this.element.querySelector(".sms-char-counter").classList.remove("hidden")
                }else{
                    // hide char counter
                    this.element.querySelector(".sms-char-counter").classList.add("hidden")
                }

            })
        })
    }
}
