// <div data-controller="process--discount">
//
// </div>
import {Controller} from "@hotwired/stimulus";
import {debounce} from "lodash-es";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = [
        "currencySection", "currencyInput",
        "percentSection", "percentInput",
        "toggleButton"
    ];
    static classes = [""]
    static values = {};

    initialize() {
        this.currencyInputTarget.value = this.currency_to_number(this.currencyInputTarget.value)
        this.percentInputTarget.value = this.percent_to_number(this.percentInputTarget.value)

        if (this.percentInputTarget.value > 0)
            this.showPercent();
        else
            this.showCurrency();

        this.currencyInputTarget.addEventListener("input", debounce(() => {
            this.percentInputTarget.value = "";
        }), 250);
        this.currencyInputTarget.addEventListener("blur", () => {
            this.currencyInputTarget.value = this.number_to_currency(this.currencyInputTarget.value)
        });

        this.percentInputTarget.addEventListener("input", debounce(() => {
            this.currencyInputTarget.value = "";
        }), 250);
        this.percentInputTarget.addEventListener("blur", () => {
            this.percentInputTarget.value = this.number_to_percent(this.percent_to_number(this.percentInputTarget.value))
        });


        this.currencyInputTarget.value = this.number_to_currency(this.currencyInputTarget.value)
        this.percentInputTarget.value = this.number_to_percent(this.percentInputTarget.value)

    }

    showCurrency() {
        this.currencySectionTarget.classList.remove("hidden");
        this.percentSectionTarget.classList.add("hidden");
        this.toggleButtonTarget.innerText = "%";
    }

    showPercent() {
        this.currencySectionTarget.classList.add("hidden");
        this.percentSectionTarget.classList.remove("hidden");
        this.toggleButtonTarget.innerText = "€";
    }

    toggle() {
        if (this.currencySectionTarget.classList.contains("hidden")) {
            this.showCurrency();
        } else {
            this.showPercent();
        }
    }

    currency_to_number(currency) {
        // format: € 1.000,00
        return currency.replace(/[^0-9,]/g, '').replace(',', '.');
    }

    number_to_currency(number) {
        // format: € 1.000,00
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(number);
    }

    percent_to_number(percent) {
        // format: 10%
        return percent.replace(/[^0-9,]/g, '').replace(',', '.');
    }

    number_to_percent(number) {
        // format: 10%
        number = number / 100;
        return new Intl.NumberFormat('de-DE', {style: 'percent', minimumFractionDigits: 0}).format(number);
    }
}
