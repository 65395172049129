// <div data-controller="select-search">
//
// </div>
import {Controller} from "@hotwired/stimulus";
import TomSelect from "tom-select";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = [""];
    static classes = [""]
    static values = {delete: String};

    initialize() {
        // let plugins = ['dropdown_input'];
        let plugins = [];
        if (this.hasDeleteValue) {
            plugins.push('remove_button');
        }

        this.tomselect = new TomSelect(this.element, {
                plugins: plugins,
                onItemAdd: (value, item) => {
                    console.log('onItemAdd');
                    // After option is selected, search input blur
                    this.element.parentElement.querySelector('input').blur();
                },
                render: {
                    no_results: function (data, escape) {
                        return '<div class="no-results">Não foram encontrados resultados para "' + escape(data.input) + '"</div>';
                    },
                }
            }
        )
    }

    click(event) {
        this.tomselect.open();
    }
}
