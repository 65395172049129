import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="accordion"
export default class extends Controller {
    static targets = ["item"];

    connect() {
        let accordionItems = []
        this.itemTargets.forEach(i => {
            accordionItems.push(
                {
                    id: i.id,
                    triggerEl: i,
                    targetEl: this.element.querySelector(`#${i.getAttribute("aria-controls")}`),
                    active: i.getAttribute("aria-expanded") === "true"
                }
            )
        })
        // options with default values
        const options = {
            alwaysOpen: false,
            activeClasses: this.element.dataset.activeClasses ? this.element.dataset.activeClasses : 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white',
            inactiveClasses: this.element.dataset.inactiveClasses ? this.element.dataset.inactiveClasses : 'text-gray-500 dark:text-gray-400'
        };

        const accordion = new Accordion(accordionItems, options);
    }
}
