// <div data-controller="process--select-state">
//
// </div>
import {Controller} from "@hotwired/stimulus"

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    initialize() {
        this.element.addEventListener("change", () => {
            let selectedOption = this.element.options[this.element.selectedIndex]
            this.class_applier(this.element, selectedOption.dataset.textClass, selectedOption.dataset.bgClass)

            let tswrapper = this.element.parentElement.querySelector(".ts-wrapper")
            if (tswrapper) {
                this.class_applier(tswrapper, selectedOption.dataset.textClass, selectedOption.dataset.bgClass)
            }

        })
    }

    class_applier(element, text_class, bg_class) {
        // remove any existing classes that match the looks like "bg-COLOR-INTENSITY" or "text-COLOR-INTENSITY"
        element.classList.remove(...Array.from(element.classList).filter(c => c.match(/^(bg|text)-[a-z]+-[0-9]+$/)))
        element.classList.add(`${text_class}`, `${bg_class}`)
    }


}
