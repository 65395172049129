// <div data-controller="list--users--location-select">
//
// </div>
import {Controller} from "@hotwired/stimulus";
import TomSelect from "tom-select";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = [""];
    static classes = [""]
    static values = {};

    initialize() {
        // get options from data-options attribute
        let select_options = []
        let selected_options = []

        if (this.element.dataset.items) {
            let aux = JSON.parse(this.element.dataset.items)
            console.log(aux)
            for (let i = 0; i < aux.length; i++) {
                selected_options.push(aux[i])
            }
        }

        if (this.element.dataset.options) {
            let aux = JSON.parse(this.element.dataset.options)
            for (let i = 0; i < aux.length; i++) {
                let item = aux[i]
                select_options.push(
                    {
                        text: item[0],
                        id: item[1],
                    }
                )
            }
        }


        // each item contains name, color and id


        new TomSelect(this.element, {
                plugins: {
                    remove_button: {
                        title: 'Remove this item',
                    }
                },
                onItemAdd: function (e) {
                    this.setTextboxValue('');
                    this.refreshOptions();
                },
                persist: false,
                valueField: 'id',
                options: select_options,
                items: selected_options,
            }
        )
    }
}
